<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="topsubmenu auto">
          <a-radio-group default-value="2" size="small" @change="changeSubmenu" button-style="solid">
            <a-radio-button value="1">
              消息提醒
            </a-radio-button>
            <a-radio-button value="2">
              定时任务
            </a-radio-button>
            <a-radio-button value="3">
              登录黑名单
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="content-title auto">
          <div class="coustom-title fl">定时任务（{{count}}个）</div>
        </div>
          
        <div class="coustom-title-mes auto">
          <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />一共有定时任务 {{count}} 个
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-radio-group v-model="filter.repeatType" button-style="solid" size="small" class="m-r-10 fl" @change="changeType">
                <a-radio-button value="once">
                  单次
                </a-radio-button>
                <a-radio-button value="repeat">
                  重复
                </a-radio-button>
              </a-radio-group>
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addRoles"> 添加定时任务 </a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :loading="tableLoading" :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false" :scroll="{ x: 1260 }">
            <span slot="tableindex" slot-scope="text,record,index">{{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}</span>
            <div slot="enabledStatus" slot-scope="text, record, index">
              <a-switch v-model="record.enabledStatus" size="small" @change="onChange(record, index)" />
            </div>
            <div slot="executedStatus" slot-scope="text, record">
              <a-badge status="success" v-if="text == 1" text="成功" />
              <a-badge status="warning" v-if="text == 0" text="处理中" />
              <a-badge status="error" v-if="text == -1" text="失败" />
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5" @click="editHandle(record)">编辑</a-button>
              <a-popconfirm
                title="确定要立即执行吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="executeHandle(record)"
              >
                <a-button type="primary" size="small" class="m-r-5">立即执行</a-button>
              </a-popconfirm>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteHandle(record)"
              >
                <a-button type="primary" size="small" class="m-r-5 bgred borred">删除</a-button>
              </a-popconfirm>
              <a-button size="small" @click="detailHandle(record)">执行结果</a-button>
            </div>
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-radio-group v-model="filter.repeatType" button-style="solid" size="small" class="m-r-10 fl" @change="changeType">
                <a-radio-button value="once">
                  单次
                </a-radio-button>
                <a-radio-button value="repeat">
                  重复
                </a-radio-button>
              </a-radio-group>
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addRoles"> 添加定时任务 </a-button>
            </div>
          </custom-page>
        </div>
      </a-spin>
    </a-layout-content>
    <a-modal v-model="addVisible" title="添加定时任务" @cancel="addClose" @ok="addSubmit" width="590px">
      <a-spin :spinning="editLoading">
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            id="no-margin-form"
            class="ant-advanced-search-form"
            :label-col="{span: 4}"
            :wrapper-col="{span: 19}"
          >
          <a-form-model-item label="任务名称" prop="name">
            <a-input v-model="form.name" placeholder="请输入任务名称" size="small"/>
          </a-form-model-item>
          <a-form-model-item label="任务类型" prop="taskType">
            <a-radio-group v-model="form.taskType" size="small">
              <a-radio value="sql" @click="changeTaskType">
                SQL
              </a-radio>
              <a-radio value="etl" @click="changeTaskType">
                ETL
              </a-radio>
              <a-radio value="cls" @click="changeTaskType">
                内置任务
              </a-radio>
              <a-radio value="groovy" @click="changeTaskType">
                动态代码
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="重复类型" prop="repeatType">
            <a-radio-group v-model="form.repeatType" size="small">
              <a-radio value="once" @click="changeRepeatType">
                单次
              </a-radio>
              <a-radio value="repeat" @click="changeRepeatType">
                重复
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="执行时间" prop="executedTime" v-if="form.repeatType === 'once'">
            <a-date-picker
              v-model="form.executedTime"
              :show-time="{ format: 'HH:mm:ss' }"
              format="YYYY-MM-DD HH:mm:ss"
              :disabled-date="disabledDate"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择时间"
              size="small"
            />
          </a-form-model-item>
          <a-form-model-item label="执行时间" prop="executedTime" v-if="form.repeatType === 'repeat'">
            <a-input size="small" placeholder="请输入Cron表达式" v-model="form.executedTime"></a-input>
          </a-form-model-item>
          <a-form-model-item label="执行语句" prop="executedContent" v-if="form.taskType === 'sql'">
            <a-input type="textarea" placeholder="请输入SQL语句" v-model="form.executedContent"></a-input>
          </a-form-model-item>
          <a-form-model-item label="执行语句" prop="executedContent" v-if="form.taskType === 'etl' || form.taskType === 'groovy'">
            <a-upload
              name="file"
              :multiple="true"
              :headers="headers"
              :transform-file="xmlHandle"
              :showUploadList="false"
            >
              <a-button size="small" class="m-t-5">{{form.taskType === 'etl' ? '上传XML文件' : '上传动态代码文件'}}</a-button>
            </a-upload>
            <br>
            <a :href="form.executedContent" v-if="form.executedContent" target="_blank" class="alink">上传完成</a>
          </a-form-model-item>
          <a-form-model-item label="内置任务" prop="executedContent" v-if="form.taskType === 'cls'">
            <a-select v-model="form.executedContent" placeholder="请选择任务" size="small" allowClear>
              <a-select-option :value="labelSelectItem.id" v-for="(labelSelectItem, labelSelectIndex) in internalOptions" :key="labelSelectIndex">{{labelSelectItem.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="启用状态" prop="enabledStatus">
            <a-switch v-model="form.enabledStatus" size="small"></a-switch>
          </a-form-model-item>
          <a-form-model-item label="任务描述" prop="description">
            <a-input v-model="form.description" placeholder="请输入任务描述" size="small"/>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
    <a-modal v-model="detailVisible" title="结果详情" @ok="detailClose" width="590px">
      <p>任务名称： {{current_row.name}}</p>
      <a-table :loading="dialogTableLoading" :rowKey="(record,index)=>{return index}" :columns="tablecolumns2" :data-source="tabledata2" size="middle" :pagination="false">
        <div slot="executedResultStatus" slot-scope="text, record">
          <a-badge status="success" v-if="text == 1" text="成功" />
          <a-badge status="warning" v-if="text == 0" text="处理中" />
          <a-badge status="error" v-if="text == -1" text="失败" />
        </div>
        <div slot="action" slot-scope="text, record">
          <a-button size="small" @click="detailText(record)">详情</a-button>
        </div>
      </a-table>
      <div class="content-bar auto" style="padding-bottom: 0;">
        <custom-page :total="count2" @getPageMes="getPageMes2" :page="filter2.current_page" :limit="filter2.page_size"></custom-page>
      </div>
    </a-modal>
    <a-modal v-model="textVisible" title="详情" @ok="textClose" :footer="null" width="384px">
      {{detailTextWords}}
    </a-modal>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      editLoading: false,
      tableLoading: true,
      headers: {
        authorization: 'authorization-text',
      },
      loading: false,
      filter: {
        repeatType: 'once',
        current_page: 1,
        page_size: 10
      },
      tablecolumns: [
        {
          title: '序号',
          dataIndex: 'tableindex',
          scopedSlots: { customRender: 'tableindex' },
          width: 70
        },
        {
          title: '任务名称',
          dataIndex: 'name',
          width: 150
        },
        {
          title: '任务描述',
          dataIndex: 'description',
          width: 150
        },
        {
          title: '创建时间',
          dataIndex: 'createdDate',
          width: 200
        },
        {
          title: '执行时间',
          dataIndex: 'executedTime',
          width: 200
        },
        {
          title: '启用状态',
          dataIndex: 'enabledStatus',
          scopedSlots: { customRender: 'enabledStatus' },
          width: 100
        },
        {
          title: '执行状态',
          dataIndex: 'executedStatus',
          scopedSlots: { customRender: 'executedStatus' },
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'width': '295px',
              }
            }
          }
        }
      ],
      tabledata: [],
      count: 0,
      addVisible: false,
      form: {
        name: '',
        taskType: 'sql',
        repeatType: 'once',
        executedTime: undefined,
        executedContent: '',
        enabledStatus: true,
        description: ''
      },
      rules: {
        name: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        executedTime: [{ required: true, message: '请输入执行时间', trigger: 'blur' }],
        executedContent: [{ required: true, message: '请添加内容', trigger: 'change' }]
      },
      detailVisible: false,
      tablecolumns2: [
        {
          title: '开始执行时间',
          dataIndex: 'startTime',
          width: 180
        },
        {
          title: '执行结束时间',
          dataIndex: 'endTime',
          width: 180
        },
        {
          title: '执行状态',
          dataIndex: 'executedResultStatus',
          scopedSlots: { customRender: 'executedResultStatus' },
          width: 60
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tabledata2: [],
      count2: 0,
      filter2: {
        current_page: 1,
        page_size: 10
      },
      textVisible: false,
      isAdd: false,
      current_row: {},
      detailTextWords: '',
      dialogTableLoading: false,
      internalOptions: []
    }
  },
  methods: {
    changeSubmenu (e) {
      var value = Number(e.target.value)
      console.log('value', value)
      switch (value) {
        case 1:
          this.$router.push('/system/other/message');
          break;
        case 2:
          this.$router.push('/system/other/task');
          break;
        case 3:
          this.$router.push('/system/other/authentication');
          break;
        default:
          break;
      }
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate(current) {
      // Can not select days before today and today
      // console.log(111, new Date(current))
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return current && current < today;
    },
    changeTaskType (e) {
      this.form.executedContent = undefined
    },
    changeRepeatType (e) {
      this.form.executedTime = undefined
    },
    xmlHandle (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.executedContent = reader.result
      }
      reader.onerror = (error) => {
        this.$message.error(error)
      }
    },
    textClose () {
      this.textVisible = false
    },
    detailText (row) {
      this.textVisible = true
      this.detailTextWords = row.executedResultContent
    },
    getPageMes2 (obj) {
      this.filter2.current_page = obj.pageNumber
      this.filter2.page_size = obj.pageSize
      this.detailHandleFn()
    },
    detailHandle (row) {
      this.current_row = row
      this.detailVisible = true
      this.filter2.current_page = 1
      this.detailHandleFn()
    },
    async detailHandleFn () {
      this.dialogTableLoading = true
      let res = await System.getTaskDetailLogging(this.current_row.id, this.filter2)
      this.dialogTableLoading = false
      if (!res['code']) {
        this.tabledata2 = res['data']
        this.count2 = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    detailClose () {
      this.current_row = {}
      this.detailVisible = false
    },
    async onChange (row, index) {
      // console.log(row.enabledStatus)
      let type = row.enabledStatus ? 'open' : 'close'
      let res = await System.changeTaskStatus(type, row.id)
      if (!res['code']) {
        this.$message.success('操作成功！')
        this.getTask()
      } else {
        row.enabledStatus = false
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async executeHandle (row) {
      this.current_row = row
      let res = await System.executeTask(this.current_row.id)
      if (!res['code']) {
        this.$message.success('操作成功！')
        this.getTask()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async editHandle (row) {
      this.current_row = row
      this.isAdd = false
      this.addVisible = true
      let res = await System.getTaskDetail(this.current_row.id)
      if (!res['code']) {
        if (res['data']['taskType'] === 'sql') {
          // res['data']['executedContent'] = decodeURI(atob(res['data']['executedContent']))
          res['data']['executedContent'] = atob(res['data']['executedContent'])
        } else if (res['data']['taskType'] === 'etl') {
          res['data']['executedContent'] = 'data:application/vnd.ms-excel;base64,' + res['data']['executedContent']
        }
        this.form = {
          name: res['data']['name'],
          taskType: res['data']['taskType'],
          repeatType: res['data']['repeatType'],
          executedTime: res['data']['executedTime'],
          executedContent: res['data']['executedContent'],
          enabledStatus: Boolean(res['data']['enabledStatus']),
          description: res['data']['description']
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    addClose () {
      this.current_row = {}
      this.addVisible = false
      this.form = {
        name: '',
        taskType: 'sql',
        repeatType: 'once',
        executedTime: undefined,
        executedContent: '',
        enabledStatus: true,
        description: ''
      }
    },
    addSubmit () {
      this.$refs['ruleForm'].validate( async validate => {
        if (validate) {
          let form = JSON.parse(JSON.stringify(this.form))
          let res = null
          if (form.taskType === 'sql') {
            // form.executedContent = btoa(encodeURI(form.executedContent))
            form.executedContent = btoa(form.executedContent)
          } else if (form.taskType === 'etl') {
            // console.log(form.executedContent)
            form.executedContent = form.executedContent.split(',')[1]
          }
          form.enabledStatus = form.enabledStatus ? 1 : 0
          this.editLoading = true
          if (!this.isAdd) {
            res = await System.changeTask(this.current_row.id, form)
          } else {
            res = await System.addTask(form)
          }
          this.editLoading = false
          if (!res['code']) {
            this.$message.success('保存成功！')
            this.addClose()
            this.getTask()
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    addRoles () {
      this.isAdd = true
      this.addVisible = true
    },
    async deleteHandle (row) {
      let res = await System.deleteTask(row.id)
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getTask()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    changeType (e) {
      this.filter.repeatType = e.target.value
      this.filter.current_page = 1
      this.getTask()
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getTask()
    },
    async getTask () {
      this.tableLoading = true
      let res = await System.getTask(this.filter)
      this.tableLoading = false
      if (!res['code']) {
        let tabledata = []
        res['data'].map(item => {
          item['enabledStatus'] = Boolean(item['enabledStatus'])
          tabledata.push(item)
        })
        this.tabledata = tabledata
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getTaskCode () {
      let res = await System.getTaskCode('internal')
      if (!res['code']) {
        this.internalOptions = res['data']
        this.getTask()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getTaskCode()
  },
  mounted () {
  }
};
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
  color: rgba(0,0,0,0.85);
}
.add-btn {
  padding-bottom: 10px;
}
.add-btn, .add-item {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.add-item {
  text-align: center;
  padding: 10px 0;
}

.topsubmenu {
  padding-bottom: 0;
}
.coustom-title {
  color: rgba(0,0,0,0.85);
}
</style>
